import React, { useEffect, useState } from 'react';
import '../aspecto/BaseDeposito.css';
import { initializeSocket } from "../utils/socket"; // Importa el socket
import { utils, writeFile } from 'xlsx';
import Select from 'react-select';


function BaseDeposito() {
  const [orders, setOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [columns, setColumns] = useState([
    { key: 'CodigoOrden', label: 'CÓDIGO ORDEN' },
    { key: 'SubMarca', label: 'SUB MARCA' },
    { key: 'IdCliente', label: 'ID CLIENTE' },
    { key: 'NombreTrabajo', label: 'NOMBRE TRABAJO' },
    { key: 'Producto', label: 'PRODUCTO' },
    { key: 'Estado', label: 'ESTADO' },
    { key: 'Cantidad', label: 'CANTIDAD' },
    { key: 'PrecioUnitario', label: 'PRECIO UNITARIO' },
    { key: 'CostoFinal', label: 'COSTO FINAL' },
    { key: 'DescuentoAplicado', label: 'DESCUENTO APLICADO' },
    { key: 'Modo', label: 'MODO' },
    { key: 'LugarRetiro', label: 'LUGAR RETIRO' },
    { key: 'FechaIngresoOrden', label: 'FECHA INGRESO ORDEN' },
    { key: 'OrdNotaCliente', label: 'NOTA CLIENTE' }
  ]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [nuevoEstado, setNuevoEstado] = useState('');
  const [estadosOrden, setEstadosOrden] = useState([]);
  const [filters, setFilters] = useState({ date: '', estado: '' });

  useEffect(() => {
    fetchAllOrders();
    fetchEstadosOrdenes(); // Cargar los estados
    
    // Inicializar socket
    const socket = initializeSocket();

    // Suscribirse al evento "actualizado"
    socket.on("actualizado", () => {
      console.log("Evento recibido: actualizando órdenes");
      fetchAllOrders();
      fetchEstadosOrdenes(); // Cargar los estados
    });

    // Cleanup: desconectar socket al desmontar el componente
    return () => {
      socket.off("actualizado"); // Desuscribirse del evento
    };
  }, []); // Solo se ejecuta una vez al montar

  const fetchEstadosOrdenes = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/apiordenes/estados/list`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error al obtener los estados de órdenes');
        }
        return response.json();
      })
      .then((data) => {
        setEstadosOrden(data);
      })
      .catch((error) => console.error('Error al obtener estados de órdenes:', error));
  };

  const fetchAllOrders = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/apiordenes/data`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error al obtener órdenes');
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          console.log('Todas las órdenes recibidas:', data);
          setOrders(data);
        } else {
          console.error('La respuesta no es un array de órdenes', data);
        }
      })
      .catch((error) => console.error('Error al obtener órdenes:', error));
  };

  const resetFilters = () => {
    fetchAllOrders(); // Vuelve a cargar todas las órdenes desde el backend
    setFilters({ date: '', estado: '' }); // Limpia los campos de filtro
  };
  

  const formatNumber = (value) => {
    return typeof value === 'number' ? value.toFixed(2) : value;
  };

  const handleAddOrder = () => {
    // Lógica para agregar una orden
  };

  const handleDeleteSelectedRows = () => {
    const remainingOrders = orders.filter((_, index) => !selectedOrders.includes(index));
    setOrders(remainingOrders);
    setSelectedOrders([]);
  };

  const handleSelectRow = (index) => {
    if (selectedOrders.includes(index)) {
      setSelectedOrders(selectedOrders.filter((i) => i !== index));
    } else {
      setSelectedOrders([...selectedOrders, index]);
    }
  };

  const handleInputChange = (e, rowIndex, key) => {
    const updatedOrders = [...orders];
    updatedOrders[rowIndex][key] = e.target.value;
    setOrders(updatedOrders);
  };

  const openModal = (order) => {
    setSelectedOrder(order);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedOrder(null);
  };

  const handleFilterChange = (selectedOptions) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      estados: selectedOptions || [],
    }));
  };
  


  const handleDownloadExcel = () => {
    const filteredOrders = orders
    .filter((order) => order.ExportadoOdoo === false) // Filtrar órdenes donde ExportaOdoo es false
    .map((order) => ({
      'Líneas del pedido/Producto': order.CodigoOdoo + (order.Modo === 'Normal' ? 'N' : order.Modo === 'Urgente' ? 'U' : ''),
      'Referencia del pedido': order.CodigoOrden,
      'Líneas del pedido/Cantidad': order.Cantidad ? parseFloat(order.Cantidad) : 0,
      'Cliente': order.IdCliente,
      'Referencia cliente': order.NombreTrabajo,
      'Modo': order.Modo,
    }));

    if (filteredOrders.length > 0) {
      const worksheet = utils.json_to_sheet(filteredOrders);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, 'Órdenes');
      writeFile(workbook, 'Ordenes.xlsx');  
      
      // Llamar a la API para actualizar el estado de las órdenes exportadas
      try {
        const orderIds = orders
          .filter((order) => order.ExportadoOdoo === false)
          .map((order) => order.IdOrden);
      
        const token = localStorage.getItem('token');
        if (!token) {
          alert('No se encontró un token de autorización. Por favor, inicie sesión nuevamente.');
          return;
        }
      
        fetch(`${process.env.REACT_APP_BACKEND_URL}/apiordenes/actualizarExportacion`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ orderIds }),
        })
          .then((response) => {
            if (!response.ok) {
              return response.json().then((error) => {
                console.error('Error al actualizar las órdenes:', error);
                alert(error.message || 'No se pudo actualizar el estado de las órdenes.');
              });
            } else {
              alert('Órdenes exportadas y actualizadas correctamente.');
            }
          })
          .catch((error) => {
            console.error('Error al actualizar las órdenes:', error);
            alert('Hubo un problema al intentar actualizar las órdenes.');
          });
      } catch (error) {
        console.error('Error en el bloque try-catch:', error);
        alert('Error inesperado al intentar actualizar las órdenes.');
      }
    } else {
      alert('No existen ordenes a exportar');
    }   
  };

  const applyFilters = () => {
    let filtered = [...orders];
  
    // Filtro por fecha
    if (filters.date) {
      filtered = filtered.filter((order) => {
        const orderDate = new Date(order.OrdFechaIngresoOrden).toISOString().split('T')[0];
        return orderDate === filters.date;
      });
    }
  
    // Filtro por estados seleccionados
    if (filters.estados.length > 0) {
      const selectedEstados = filters.estados.map((estado) => estado.value.toLowerCase());
      filtered = filtered.filter((order) =>
        selectedEstados.includes(order.Estado.toLowerCase())
      );
    }
  
    setOrders(filtered);
  };
    

  const handleFilter = () => {
    let filtered = [...orders];
    
    // Normalizar fechas para comparación
    if (filters.Date) {
      filtered = filtered.filter((order) => {
        const orderDate = new Date(order.OrdFechaIngresoOrden).toISOString().split('T')[0]; // Convertir a formato YYYY-MM-DD
        return orderDate === filters.Date;
      });
    }
    
    // Filtrar por estado
    if (filters.Estado) {
      filtered = filtered.filter((order) =>
        order.Estado.toLowerCase().trim().includes(filters.Estado.toLowerCase().trim())
      );
    }
    
    return filtered;
  };

  const handleUpdateEstado = () => {
    const selectedOrderIds = selectedOrders.map((index) => orders[index].IdOrden);
    const token = localStorage.getItem('token');

    fetch(`${process.env.REACT_APP_BACKEND_URL}/apiordenes/actualizarEstado`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, // Agregar token aquí
      },
      body: JSON.stringify({
        orderIds: selectedOrderIds,
        nuevoEstado: nuevoEstado,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error al actualizar el estado de las órdenes');
        }
        return response.json();
      })
      .then((data) => {
        alert(data.message || 'Estados actualizados correctamente');
        fetchAllOrders(); // Recargar las órdenes después de actualizar
      })
      .catch((error) => {
        console.error('Error al actualizar estados:', error);
        alert('Hubo un problema al actualizar los estados.');
      });
  };

  return (
    <div className="CONTBDDPRINCIP">
      <h1 className="CONTBDDEP1">Base de Datos de Órdenes</h1>
      <div className="CONTBDDEP2">
        <div className="CONTBDDEP3">
          <button
            className="button-update-state"
            onClick={handleUpdateEstado}
            disabled={selectedOrders.length === 0 || !nuevoEstado}
          >
            Actualizar Estado
          </button>
          <select
            value={nuevoEstado}
            onChange={(e) => setNuevoEstado(e.target.value)}
            className="state-selector"
          >
            <option value="">Seleccione un estado</option>
            {estadosOrden.map((estado) => (
              <option key={estado.EOrIdEstadoOrden} value={estado.EOrNombreEstado}>
                {estado.EOrNombreEstado}
              </option>
            ))}
          </select>
          <button className="button-add-order" onClick={handleAddOrder}>
            Agregar Orden
          </button>
          <button
            className="button-delete-order"
            onClick={handleDeleteSelectedRows}
            disabled={selectedOrders.length === 0}
          >
            Eliminar Órdenes
          </button>
          <button className="button-download-excel" onClick={handleDownloadExcel}>
            Descargar Excel
          </button>
        </div>
        <div className="CONTBDDEP7">
        <label>
          Filtrar por Fecha de Ingreso:
          <input
            type="date"
            name="date"
            className="filter-date"
            value={filters.date}
            onChange={handleFilterChange}
          />
        </label>
        <label>
          Filtrar por Estado:
          <Select
            isMulti
            options={estadosOrden.map((estado) => ({
              value: estado.EOrNombreEstado,
              label: estado.EOrNombreEstado,
            }))}
            value={filters.estados}
            onChange={handleFilterChange}
            className="filter-select"
            placeholder="Seleccione estados..."
          />
        </label>
        <button onClick={applyFilters} className="button-filter">Filtrar</button>
        <button onClick={resetFilters} className="button-reset">Restablecer</button>
        </div>
      </div>

      <h2 className="CONTBDDEP8">Órdenes</h2>
      {orders.length === 0 ? (
        <p className="CONTBDDEP9">No hay órdenes disponibles.</p>
      ) : (
        <div className="CONTBDDEP10">
          <table className="CONTBDDEP11">
            <thead>
              <tr>
                <th className="CONTBDDEP12">SELECCIONAR</th>
                {columns.map((column) => (
                  <th key={column.key}>{column.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {handleFilter().map((order, rowIndex) => (
                <tr key={rowIndex} onClick={() => openModal(order)}>
                  <td className="CONTBDDEP13">
                    <input
                      type="checkbox"
                      className="CONTBDDEP14"
                      checked={selectedOrders.includes(rowIndex)}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleSelectRow(rowIndex);
                      }}
                    />
                  </td>
                  {columns.map((column) => (
                    <td key={column.key}>
                      <input
                        type="text"
                        className="editable-cell"
                        value={
                          column.key === 'CostoFinal' || column.key === 'PrecioUnitario'
                            ? formatNumber(order[column.key])
                            : order[column.key] || ''
                        }
                        onChange={(e) => handleInputChange(e, rowIndex, column.key)}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {modalVisible && selectedOrder && (
        <div className="CONTBDDEP16">
          <div className="CONTBDDEP17">
            <span className="modal-close" onClick={closeModal}>
              &times;
            </span>
            <h2>Detalles de la Orden</h2>
            <div className="CONTBDDEP19">
              {columns.map((column) => (
                <div key={column.key} className="CONTBDDEP20">
                  <strong>{column.label}:</strong>{' '}
                  {column.key === 'CostoFinal' || column.key === 'PrecioUnitario'
                    ? formatNumber(selectedOrder[column.key])
                    : selectedOrder[column.key]}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BaseDeposito;

import React, { useState, useEffect, useRef } from 'react';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import '../aspecto/ticket_autogestion.css'; // Asegúrate de que este archivo incluye los estilos mencionados
import '../aspecto/teclado.css';
import PedidoDeStock from './pedido_de_stock';

function App() {
  const [clientId, setClientId] = useState('No especificado');
  const [contact, setContact] = useState('No especificado');
  const [tipodecliente, setTipodecliente] = useState('No especificado');
  const [service, setService] = useState('');
  const [orders, setOrders] = useState([]);
  const [rowCount, setRowCount] = useState(1);
  const [inputName, setInputName] = useState('order-0');
  const [inputValue, setInputValue] = useState('');
  const [layoutName] = useState('default');
  const [showTicket, setShowTicket] = useState(false);
  const [ticketData, setTicketData] = useState(null);
  const [warningModalVisible, setWarningModalVisible] = useState(false);
  const [ticketNumber, setTicketNumber] = useState('');
  const [ticketDateTime, setTicketDateTime] = useState('');
  const [pickupLocations, setPickupLocations] = useState([]);
  const [selectedPickupLocationId, setSelectedPickupLocationId] = useState('');

  const keyboardRef = useRef(null);

  useEffect(() => {
    addRows(5);
    loadPickupLocations();
    handleFocus('order-0');
  }, []);

  const handleChange = (input) => {
    setInputValue(input);
    if (inputName) {
      if (inputName.startsWith('order-')) {
        const index = parseInt(inputName.split('-')[1], 10);
        handleOrderNumberChange(index, input);
      }
    }
  };

  const loadPickupLocations = () => {
    console.log('Cargando lugares de retiro...');
    fetch(`${process.env.REACT_APP_BACKEND_URL}/apilugaresRetiro/lugares-retiro`)
        .then((response) => {
            if (!response.ok) {
                throw new Error('Error al cargar los lugares de retiro');
            }
            return response.json();
        })
        .then((locations) => {
            console.log('Lugares de retiro cargados:', locations);
            // Filtrar el lugar que se llama "En local"
            const localPickup = locations.find(location => location.LReNombreLugar === "En local");
            if (localPickup) {
                console.log('Lugar de retiro seleccionado:', localPickup);
                setPickupLocations([localPickup]); // Asignar solo el lugar "En local"
                setSelectedPickupLocationId(localPickup.LReIdLugarRetiro); // Seleccionar automáticamente "En local"
            } else {
                console.warn('No se encontró el lugar de retiro llamado "En local"');
            }
        })
        .catch((error) => {
            console.error('Error al cargar los lugares de retiro:', error);
        });
  };


  const handleFocus = (inputName) => {
    setInputName(inputName);
    let value = '';
    if (inputName.startsWith('order-')) {
      const index = parseInt(inputName.split('-')[1], 10);
      value = orders[index]?.orderNumber || '';
    }
    setInputValue(value);
    if (keyboardRef.current) {
      keyboardRef.current.setInput(value);
    }
  };

  const handleKeyPress = (button) => {
    if (button === '{tab}' || button === '{enter}') {
      navigateVertical();
    } else if (button === '{bksp}') {
      const newValue = inputValue.slice(0, -1);
      setInputValue(newValue);
      setTimeout(() => {
        if (inputName.startsWith('order-')) {
          const index = parseInt(inputName.split('-')[1], 10);
          handleOrderNumberChange(index, newValue);
        }
        if (keyboardRef.current) {
          keyboardRef.current.setInput(newValue);
        }
      }, 0);
    } else if (button === '{clear}') {
      setInputValue('');
      setTimeout(() => {
        if (inputName.startsWith('order-')) {
          const index = parseInt(inputName.split('-')[1], 10);
          handleOrderNumberChange(index, '');
        }
        if (keyboardRef.current) {
          keyboardRef.current.setInput('');
        }
      }, 0);
    } else {
      const newValue = `${inputValue}${button}`;
      setInputValue(newValue);
      setTimeout(() => {
        if (inputName.startsWith('order-')) {
          const index = parseInt(inputName.split('-')[1], 10);
          handleOrderNumberChange(index, newValue);
        }
        if (keyboardRef.current) {
          keyboardRef.current.setInput(newValue);
        }
      }, 0);
    }
  };

  const navigateVertical = () => {
    if (inputName.startsWith('order-')) {
      const currentIndex = parseInt(inputName.split('-')[1], 10);
      const nextIndex = currentIndex + 1 < orders.length ? currentIndex + 1 : currentIndex;
      handleFocus(`order-${nextIndex}`);
      if (keyboardRef.current) {
        keyboardRef.current.setInput('');
      }
    }
  };

  const addRows = (count) => {
    const newOrders = [...orders];
    for (let i = 0; i < count; i++)
      newOrders.push({
        orderNumber: '',
        meters: '',
        costWithCurrency: '',
        clientId: '',
        contact: '',
        tipodecliente: '',
      });
    setOrders(newOrders);
  };

  const handleOrderNumberChange = (index, value) => {
    const newOrders = [...orders];
    newOrders[index] = {
      ...newOrders[index],
      orderNumber: value,
    };
    setOrders(newOrders);
    if (value) {
      fetchOrderData(value, index);
    } else {
      updateClientInfo(newOrders);
    }
  };

  const fetchOrderData = (orderNumber, index) => {
    if (!orderNumber) {
      updateClientInfo([]);
      return;
    }

    console.log('Buscando orden con código:', orderNumber);

    fetch(`${process.env.REACT_APP_BACKEND_URL}/apiordenes/data/${orderNumber}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error en la red');
        }
        return response.json();
      })
      .then((order) => {
        console.log('Respuesta recibida:', order);

        if (!order) {
          console.error('Orden no encontrada:', order);
          updateClientInfo([]);
          return;
        }

        const numericCostoFinal = parseFloat(
          (order.CostoFinal ? order.CostoFinal.toString() : '0').replace(/[^0-9.]/g, '')
        ) || 0;

        const newOrders = [...orders];
        newOrders[index] = {
          orderNumber: order.OrdCodigoOrden,
          meters: order.OrdCantidad || '',
          costWithCurrency: `${order.MonSimbolo || ''} ${numericCostoFinal.toFixed(2)}`,
          pago: order.OrdPagoRealizado === 1 ? 'Realizado' : 'No realizado',
          estado: order.EOrNombreEstado || '',
          clientId: order.CliCodigoCliente || '',
          contact: order.CliCelular || 'No especificado',
          tipodecliente: order.TCLDescripcion || 'No especificado',
        };

        setOrders(newOrders);
        updateClientInfo(newOrders);
      })
      .catch((error) => {
        console.error('Error al obtener los datos de la orden:', error);
        updateClientInfo([]);
      });
  };

  const updateClientInfo = (updatedOrders = orders) => {
    const firstOrderWithClient = updatedOrders.find((order) => order.clientId);
    if (firstOrderWithClient) {
      setClientId(firstOrderWithClient.clientId || 'No especificado');
      setContact(firstOrderWithClient.contact || 'No especificado');
      setTipodecliente(firstOrderWithClient.tipodecliente || 'No especificado');
    } else {
      setClientId('No especificado');
      setContact('No especificado');
      setTipodecliente('No especificado');
    }
  };

  const handleSubmit = () => {
    if (!selectedPickupLocationId) {
      alert('Por favor, seleccione un lugar de retiro.');
      return;
    }
  
    let totalCost = 0;
    let ticketOrders = [];
  
    orders.forEach((order) => {
      if (order.orderNumber) {
        const numericCost = parseFloat(order.costWithCurrency.replace(/[^0-9.]/g, ''));
        if (!isNaN(numericCost)) {
          totalCost += numericCost;
        }
        ticketOrders.push({
          orderId: order.orderId,
          ...order,
          costWithCurrency: order.costWithCurrency, // Mantén el símbolo de la moneda aquí
        });
      }
    });
  
    if (ticketOrders.length === 0) {
      alert('No hay órdenes válidas para mostrar en el ticket.');
      return;
    }
  
    const token = localStorage.getItem('token');
  
    fetch(`${process.env.REACT_APP_BACKEND_URL}/apiordenesRetiro/crear`, {
      method: 'POST',
      headers: { 
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Agregar token aquí
      },
      body: JSON.stringify({
        orders: ticketOrders,
        totalCost,
        lugarRetiro: selectedPickupLocationId,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error al crear la orden de retiro');
        }
        return response.json();
      })
      .then((data) => {
        const { OReIdOrdenRetiro } = data; // Obtener el ID de la orden creada
        const generatedTicketNumber = 'R-' + String(OReIdOrdenRetiro).padStart(4, '0'); // Generar el número del ticket
        
        // Obtener la fecha y hora actuales
        const now = new Date();
        const options = {
          timeZone: 'America/Montevideo',
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
        };
        const formatter = new Intl.DateTimeFormat('es-UY', options);
        const uruguayDateTime = formatter.format(now);
      
        // Establecer el número y la fecha del ticket
        setTicketNumber(generatedTicketNumber); // Establecer el número de ticket en el estado
        setTicketDateTime(uruguayDateTime); // Establecer la fecha y hora del ticket
      
        // Actualizar los datos del ticket
        setTicketData({
          clientId,
          tipodecliente,
          service,
          orders: ticketOrders,
          totalCost,
          ordenDeRetiro: generatedTicketNumber, // Usar el número de ticket generado
          timestamp: uruguayDateTime, // Guardar la fecha y hora en los datos del ticket
        });
        setShowTicket(true); // Mostrar el ticket
      })
      .catch((error) => {
        console.error('Error al crear la orden de retiro:', error);
        alert('Error al crear la orden de retiro');
      });      
  };
  
  const handlePrintTicket = () => {
    if (!ticketData) {
      alert('No hay datos del ticket para guardar.');
      return;
    }
    saveTicket(ticketData);
    window.print();
  };

  const saveTicket = (ticket) => {
    let tickets = JSON.parse(localStorage.getItem('ORDENES_DE_RETIRO')) || {};
    tickets[ticket.ordenDeRetiro] = ticket;
    localStorage.setItem('ORDENES_DE_RETIRO', JSON.stringify(tickets));
  };

  const clearOrders = () => {
    setOrders(
      orders.map((order) => ({
        orderNumber: '',
        meters: '',
        costWithCurrency: '',
        pago: '',
        estado: '',
        clientId: '',
        contact: '',
        tipodecliente: '',
      }))
    );
    updateClientInfo([]);
  };

  const layouts = {
    default: [
      '1 2 3',
      '4 5 6',
      '7 8 9',
      '0 00 {bksp}',
      'SB- DF- UVDF-',
      'ECOUV- TWC- EMB-',
      '{clear}',
    ],
  };

  const handlePickupLocationChange = (e) => {
    const selectedLocation = e.target.value;
    console.log('Lugar de retiro seleccionado:', selectedLocation);
    setSelectedPickupLocationId(selectedLocation);
  };

  return (
    <div className="container">
      <div className="content-container">
        <div className="order-info" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <p>
              <strong>Cliente:</strong> <span>{clientId}</span>
            </p>
            <p>
              <strong>Contacto:</strong> {contact}
            </p>
            <p>
              <strong>Tipo de cliente:</strong> {tipodecliente}
            </p>
          </div>
          <button id="submit-btn" onClick={handleSubmit} style={{ height: '60px', margin: '0', padding: '0 50px' }}>
            Enviar
          </button>
        </div>
      </div>
      <div
          className="pickup-location-display"
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: '30px',
            marginTop: '10px',
          }}
        >
          <p style={{ fontWeight: 'bold', fontSize: '16px', margin: 0 }}>
            Lugar de retiro: <span>{pickupLocations[0]?.LReNombreLugar || 'No especificado'}</span>
          </p>
        </div>
      <div className="table-keyboard-wrapper">
        <div className="table-container">
          <table className="order-table">
            <thead>
              <tr>
                <th>Número de orden</th>
                <th>Estado</th>
                <th>Metros</th>
                <th>Costo con Moneda</th>
                <th>Pago</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="text"
                      value={order.orderNumber}
                      placeholder="Número de orden"
                      onFocus={() => handleFocus(`order-${index}`)}
                      onChange={(e) => handleOrderNumberChange(index, e.target.value)}
                      className="order-input"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={order.estado}
                      placeholder="Estado"
                      readOnly
                      className="estado-input"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={order.meters}
                      placeholder="Metros"
                      readOnly
                      className="meters-input"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={order.costWithCurrency}
                      placeholder="Costo con Moneda"
                      readOnly
                      className="cost-with-currency-input"
                      style={{ width: '150px' }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={order.pago}
                      placeholder="Pago"
                      readOnly
                      className="pago-input"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="keyboard-container">
          <Keyboard
            keyboardRef={(r) => (keyboardRef.current = r)}
            layoutName={layoutName}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            layout={layouts}
            display={{
              '{bksp}': 'Borrar',
              '{clear}': 'Borrar Todo',
            }}
          />
        </div>
      </div>
      <div className="actions">
        <input
          type="number"
          value={rowCount}
          min="1"
          max="100"
          onChange={(e) => setRowCount(e.target.value)}
        />
        <button id="add-row" onClick={() => addRows(parseInt(rowCount))}>
          Agregar
        </button>
      </div>
      {showTicket && (
        <div id="ticket">
          <div id="ticket-content">
            <h2>Ticket</h2>
            <h3 id="ticket-number">{ticketNumber}</h3>
            <p id="ticket-date-time">{ticketDateTime}</p>
            <p>
              <strong>Cliente:</strong> <span id="ticket-client-id">{ticketData.clientId}</span>
            </p>
            <p>
              <strong>Tipo de cliente:</strong>{' '}
              <span id="tipo-client">{ticketData.tipodecliente}</span>
            </p>
            <p>
              <strong>Servicio:</strong> <span id="ticket-service">{ticketData.service}</span>
            </p>
            <table>
              <thead>
                <tr>
                  <th>Orden</th>
                  <th>Estado</th>
                  <th>Metros</th>
                  <th>Costo con Moneda</th>
                  <th>Pago</th>
                </tr>
              </thead>
                <tbody id="ticket-orders">
                {ticketData.orders.map((order, idx) => (
                  <tr key={idx}>
                    <td>{order.orderNumber}</td>
                    <td>{order.estado}</td>
                    <td>{order.meters}</td>
                    <td>{order.costWithCurrency}</td> {/* Ya incluye el símbolo de la moneda */}
                    <td>{order.pago}</td>
                  </tr>
                ))}
                <tr className="total-row">
                  <td colSpan="4">Total</td>
                  <td>{`${orders[0]?.MonSimbolo || ''} ${ticketData.totalCost.toFixed(2)}`}</td> {/* Agrega el símbolo al total */}
                </tr>
              </tbody>
            </table>
            <button id="print-ticket" className="no-print" onClick={handlePrintTicket}>
              Imprimir Ticket
            </button>
            <button
              id="close-ticket"
              className="no-print"
              onClick={() => {
                setShowTicket(false);
                clearOrders();
              }}
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
      {warningModalVisible && (
        <div id="warning-modal" className="modal">
          <div className="modal-content">
            <span
              id="close-warning-modal"
              className="close-button"
              onClick={() => setWarningModalVisible(false)}
            >
              &times;
            </span>
            <i className="fas fa-exclamation-triangle warning-icon"></i>
            <p>Orden de dos o más clientes diferentes.</p>
          </div>
        </div>
      )}
      <div className="pedido-de-stock-container">
        <PedidoDeStock />
      </div>
    </div>
  );
}

export default App;
